import React, { FunctionComponent, useState } from 'react'
import { Box, Flex, Styled, Button } from 'theme-ui'
import Card from '../Card'
import { Link as GatsbyLink } from 'gatsby'

export type Service = {
  title: String
  description: String
}

export type CallToAction = {
  heading: String
  link: String
  linkText: String
}

export type ServiceListProps = {
  services: Array<Service>
  cta?: CallToAction
  colorful?: boolean
}

const colors = [
  { background: 'orange', contentColor: 'white' },
  { background: 'blue.1', contentColor: 'white' },
  { background: 'green', contentColor: 'white' },
]

const width = ['100%', '50%']

const defaultColor = { background: 'blue.0', contentColor: 'blue.2' }

export const ServiceList: FunctionComponent<ServiceListProps> = ({
  services,
  cta,
  colorful,
}) => {
  let colorIdx = 0

  return (
    <>
      <Flex sx={{ flexWrap: 'wrap' }}>
        {services.map((service, idx) => {
          const colorSettings = colorful ? colors[colorIdx] : defaultColor

          colorIdx = colorIdx === colors.length - 1 ? 0 : colorIdx + 1

          return (
            <Flex key={idx} sx={{ width }}>
              <Card {...colorSettings}>
                <Styled.h3>{service.title}</Styled.h3>
                <Styled.p>{service.description}</Styled.p>
              </Card>
            </Flex>
          )
        })}

        {cta && (
          <Flex
            sx={{
              width,
              h3: {
                mt: [2, 3],
              },
            }}
          >
            <Card background="blue.0" centerContent centerText>
              <Styled.h3>{cta.heading}</Styled.h3>
              <Button as={GatsbyLink} to={cta.link} variant="outline">
                {cta.linkText}
              </Button>
            </Card>
          </Flex>
        )}
      </Flex>
    </>
  )
}
