import React, { FunctionComponent } from 'react'
/** @jsx jsx */
import { Styled, Grid, jsx } from 'theme-ui'
import Card from '../Card'
import { Link } from 'gatsby'
import { FluidObject } from 'gatsby-image'

export type Article = {
  title: string
  excerpt: string
  slug: string
  image?: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

export type ArticleListProps = {
  articles: Array<Article>
}

export const ArticleList: FunctionComponent<ArticleListProps> = ({
  articles,
}) => (
  <React.Fragment>
    <Grid gap={4} columns={[1, 2]}>
      {articles.map((article, idx) => {
        return (
          <Link
            key={idx}
            to={article.slug}
            sx={{
              textDecoration: 'none',
              color: 'blue.2',
              '.article-summary': {
                transition: '1s all ease',
              },
              '&:hover': {
                '.article-summary': {
                  opacity: '0.8',
                  bg: 'blue.0',
                },
              },
            }}
          >
            <Card
              background="white"
              image={article.image}
              boxShadow
              className="article-summary"
            >
              <Styled.h3>{article.title}</Styled.h3>
              <Styled.p>
                {article.excerpt.length >= 150
                  ? `${article.excerpt.substring(0, 150)}...`
                  : article.excerpt}
              </Styled.p>
            </Card>
          </Link>
        )
      })}
    </Grid>
  </React.Fragment>
)
