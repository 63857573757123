import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import { Styled } from 'theme-ui'

import Layout from '../components/Layout'
import ServiceList from '../components/ServiceList'
import ArticleList from '../components/ArticleList'
import Container from '../components/Container'
import { PageHeader } from '../types/definitions'

type IndexPageProps = {
  data: {
    home: {
      frontmatter: {
        articlesHeading: string
        servicesHeading: string
        header: PageHeader
        footer: {
          showCTA: boolean
        }
        seo: { title: string; description: string }
      }
    }
    services: {
      nodes: { frontmatter: { title: string; description: string } }[]
    }
    articles: {
      nodes: { fields: { slug: string }; frontmatter: { header: PageHeader } }[]
    }
  }
}

const IndexPage: FunctionComponent<IndexPageProps> = ({ data }) => {
  const {
    header,
    seo,
    footer,
    servicesHeading,
    articlesHeading,
  } = data.home.frontmatter

  const services = data.services.nodes.map((node) => {
    return {
      title: node.frontmatter.title,
      description: node.frontmatter.description,
    }
  })

  const articles = data.articles.nodes.map((node) => {
    return {
      title: node.frontmatter.header.title,
      excerpt: node.frontmatter.header.openingContent,
      image: node.frontmatter.header.image,
      slug: node.fields.slug,
    }
  })

  const containerSx = {
    textAlign: 'center',
    h2: {
      fontSize: [5, 6],
    },
  }

  return (
    <Layout header={header} footer={footer} seo={seo} menuOverlay>
      <Container bg="blue.0" extraSx={containerSx}>
        <Styled.h2>{servicesHeading}</Styled.h2>

        <ServiceList
          services={services}
          cta={{
            heading: 'See what else we can do for you',
            link: '/services',
            linkText: 'Our Services',
          }}
          colorful
        />
      </Container>
      <Container bg="white" extraSx={{ pb: 4, ...containerSx }}>
        <Styled.h2>{articlesHeading}</Styled.h2>
        <ArticleList articles={articles} />
      </Container>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Home {
    home: mdx(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        articlesHeading
        footer {
          showCTA
        }
        ...header
        servicesHeading
        seo {
          description
          title
        }
      }
    }
    services: allMdx(
      filter: {
        frontmatter: {
          templateKey: { eq: "services-page" }
          isKeyService: { eq: true }
        }
      }
    ) {
      nodes {
        frontmatter {
          title
          description
        }
      }
    }
    articles: allMdx(
      filter: { frontmatter: { templateKey: { eq: "article-page" } } }
      limit: 2
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          ...header
        }
      }
    }
  }
`
